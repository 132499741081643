.sync-popover {
  line-height: 16px;
  max-width: 200px;
  text-align: center;
}
.sync-popover .icons {
  margin: 1em 0;
  color: rgba(0, 0, 0, 0.45);
}
.sync-popover .icons svg {
  font-size: 1.5em;
  margin-right: 0.5em;
  vertical-align: text-bottom;
}
